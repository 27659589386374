<template>
    <div class="styled">
        <VDrawer
                v-model="drawer"
                :right="isRight"
                :classes="{ content: 'vts-drawer__content_' + location }"
                :transition="'slide-' + location" bg-transition="fade"
        >
            <div class="w-100 d-flex justify-content-between">
                <quick-payment/>
                <cs-button @handle-click="drawer = false" icon-left="cancel" type="warning">Close</cs-button>
            </div>
            <div v-html="content"/>

        </VDrawer>

        <cs-button @handle-click="drawer = !drawer"
                   :icon-left="buttonIcon"
                   :is-outline="true"
                   type="secondary"
                   id="admin-sidebar-button"
        />
    </div>
</template>

<script>

import { VDrawer } from 'vuetensils/src/components'

export default {
  name: 'AdminSidebar',
  components: {
    VDrawer
  },
  props: {
    contentId: {
      type: String,
      default: 'admin-drawer'
    },
    content: {
      type: String,
      default: '<h5>default</h5>'
    },
    location: {
      type: String,
      default: 'top'
    }
  },
  data () {
    return {
      drawer: false
    }
  },
  mounted () {
    const drawerContent = document.getElementById(this.contentId)
    this.$nextTick(() => {
      const drawerDestination = document.getElementById('admin-sidebar-content')
      console.group('adminSidebar')
      console.dir(drawerContent)
      console.dir(drawerDestination)

      // drawerDestination.appendChild(drawerContent)
      console.groupEnd()
    })
  },
  computed: {
    buttonIcon () {
      return this.location === 'top' ? 'chevron-square-down' : 'bars'
    },
    isRight () {
      return this.location === 'right'
    }
  }
}
</script>

<style>
.vts-drawer {
    background: rgba(23, 106, 150, 0.25);
}

.vts-drawer__content {
    padding: 20px;
    color: #070707;
    background: linear-gradient(90deg, #F9DE70, #FFFBAB);
    transition: 0.3s ease transform;
}

.vts-drawer__content_top {

    max-width: 100%;
    max-height: 50%;
}

.vts-drawer__content_right {
    padding-top: 50px;
}

.vts-drawer__content_left {
    padding-top: 50px;
}

.fade-enter-active,
.fade-leave-active {
    transition: opacity 0.5s ease;
}

.fade-enter,
.fade-leave-to {
    opacity: 0;
}

.slide-right-enter-active,
.slide-right-leave-active {
    transition: opacity 0.5s ease, transform 0.5s ease;
}

.slide-right-enter-from,
.slide-right-leave-to {
    opacity: 0;
    transform: translateX(-100%);
}

.slide-top-enter-active,
.slide-top-leave-active {
    transition: opacity 0.5s ease, transform 0.5s ease;
}

.slide-top-enter-from,
.slide-top-leave-to {
    opacity: 0;
    transform: translateY(-100%);
}
</style>
